<template>
  <div
    class="primary"
    style="overflow-x: hidden;"
  >
    <vs-row
      vs-justify="start"
      :style="{background: `${cor1} ${bgInterval} fixed`}"
    >
      <div class="blank-box">
        <h2 class="blank-title">
          {{ boxName.toUpperCase().replace("-", " ") }}
        </h2>
        <img
          style="width: 130px; padding-bottom: 60px;"
          :src="require('@/assets/icons/page-not-found.svg')"
        >
        <h3 class="blank-info">
          Sem dados na base <br>...
        </h3>
      </div>
    </vs-row>
  </div>
</template>
<script>
export default {
  name: 'BlankBox',
  props: {
    boxName: String,
  },
  data: ()=>({
  }),
  computed: {
    bgInterval() {
      return this.$store.state.configBox.bgInterval
    },
    cor1() {
      return this.$store.state.configBox.cor1
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.blank-box{
    min-height: 550px;
    padding-bottom: 100%;
    text-align: center;
    margin: 0 auto;
}

.blank-title{
    padding-bottom:110px;
    padding-top:20px;
    font-size:2.4rem;
    color:white;
}
.blank-info{
    font-size:1.8rem;
    color:white;
}

</style>